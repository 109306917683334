/* ================================
 * Parallax module
 * ================================ */

(function (App, $, window) {
  /** @type {Boolean} Is local debug active? */
  const localDebug = false;
  /** @type {Boolean} Is debug active? */
  const debug = App.hasDebug() && localDebug;
  /** @type {Function} Log helper */
  const { log } = App.components.Debugger(debug, 'Parallax');

  /** @type {Boolean} Does the browser supports custom properties? */
  const supportsCustomPropeties =
    window.CSS && window.CSS.supports && window.CSS.supports('--fake-var', 0);

  /** @type {Boolean} Is the user on a mobile device? */
  const isMobile = window.App.isMobile();

  /** @type {Object} Defaults Parallax options */
  const defaults = {
    selector: '[data-speed]',
    speed: 0.2,
  };

  /**
   * Parallax class
   *
   * @param  {Object} options Options to pass to the class
   * @return {Object}         The created instance
   */
  function Parallax(options) {
    if (!(this instanceof Parallax)) return new Parallax(options);
    log('constructor');

    if (!supportsCustomPropeties) {
      log('CSS custom properties are not supported, no parallax.');
    }

    if (isMobile) {
      log('no parallax on mobile devices, sorry.');
    }

    this.isActive = supportsCustomPropeties && !isMobile;
    this.options = $.extend(true, {}, defaults, options);
    this.$parallaxItems = $(this.options.selector);
    this.items = [];
    this.resizeTimer = 0;

    // Bind methods to instance
    this.initItem = this.initItem.bind(this);
    this.setItemsStyle = this.setItemsStyle.bind(this);
    this.resizeHandler = this.resizeHandler.bind(this);
    this.resizeEndHandler = this.resizeEndHandler.bind(this);
    this.scrollHandler = this.scrollHandler.bind(this);

    return this;
  }

  /**
   * Initialize the parallax
   */
  function init() {
    log('init');
    // Check again for browser support
    if (!this.isActive) {
      return this;
    }

    this.$parallaxItems
      .css({
        transform: 'translate3d(0, calc( var(--scrollparallax) * 1px ), 0)',
      })
      .each(this.initItem);

    window.addEventListener('scroll', this.scrollHandler, {
      passive: true,
    });
    window.addEventListener('resize', this.resizeHandler);

    return this;
  }

  /**
   * Destroy the instance
   */
  function destroy() {
    log('destroy');
    window.removeEventListener('scroll', this.scrollHandler);
  }

  /**
   * Setup all items
   * @param  {Integer} index
   * @param  {Node}    element
   */
  function initItem(index, element) {
    const $element = $(element);
    const item = {
      speed: $element.data('speed') || this.options.speed,
      node: element,
      offsetTop: $element.offset().top + $element.outerHeight() / 2,
    };

    this.items.push(item);
  }

  /**
   * Handler for the resize event
   */
  function resizeHandler() {
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(this.resizeEndHandler, 300);
  }

  /**
   * Update items data on resize
   */
  function resizeEndHandler() {
    this.setItemsStyle({ type: 'resize' });
  }

  /**
   * Handler for the passive scroll event
   */
  function scrollHandler() {
    this.setItemsStyle({ type: 'scroll' });
  }

  /**
   * Set items style and position
   * @param {String} type The type of event triggering the method
   */
  function setItemsStyle(event) {
    const resetOffset = event.type === 'resize';
    const scroll = window.pageYOffset + window.innerHeight / 2;

    $.each(this.items, function (index, item) {
      // Reset offset when needed
      if (resetOffset) {
        const $element = $(item.node);
        const { transform } = item.node.style;
        item.node.style.transform = '';
        item.offsetTop = $element.offset().top + $element.outerHeight() / 2;
        item.node.style.transform = transform;
      }

      const offset = scroll - item.offsetTop;
      item.node.style.setProperty('--scrollparallax', item.speed * offset);
    });
  }

  /**
   * Declare prototype
   * @type {Object}
   */
  Parallax.prototype = {
    init,
    destroy,
    initItem,
    resizeHandler,
    resizeEndHandler,
    scrollHandler,
    setItemsStyle,
  };

  // Export class
  window.Parallax = Parallax;

  /* ===========================================================================
   * Auto init parallax
   * ======================================================================== */

  const parallax = new Parallax().init();

  $(window).on('load', function () {
    if (parallax.isActive) {
      parallax.setItemsStyle({ type: 'resize' });
    }
  });

  // Attach the parallax instance to the app components' object
  App.components.parallax = parallax;
})(App, jQuery, window);
